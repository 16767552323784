/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

import ResponsiveBootstrapToolkit from 'responsive-toolkit';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.css';
//import Waypoint from 'waypoints';
import 'waypoints/lib/jquery.waypoints.min.js';
import Cookies from 'js-cookie'

//#########################################################################
// FIXME remove before release
//
//let cookieKey = "ribcraft_draft_enabled";
//
//if (window.location.href.indexOf("unlock_draft_1439813232") > -1) {
//
//    Cookies.set(cookieKey, "true");
//
//}
//
//let cookie = Cookies.get(cookieKey);
//
//if (cookie !== "true") {
//
//    window.location.replace("http://ribcraft.co.uk/");
//
//}
//
//
//#########################################################################


(function($, viewport) {



    //#########################################################################
    //#########################################################################
    //Common functions
    //

    //#########################################################################
    //Create new resizeWidth event
    var resizeWidthFunction = function () {

        var newWidth = $(window).width();
        var lastWidth = $(window).data('resizeWidthLast');

        var lastWidthDefined = typeof lastWidth !== 'undefined';
        if (lastWidthDefined || (newWidth !== lastWidth)) {
            $(window).data('resizeWidthLast', newWidth);
            if (lastWidthDefined) {
                $(window).trigger('resizeWidth');
            }
            return;
        }

    };

    $(window).load(resizeWidthFunction);
    $(window).resize(resizeWidthFunction);

    //#########################################################################

    function fadeOutElement(element) {
        return (function (elementInner) {
            return function () {

                elementInner.fadeOut(1000);
            };

        }) (element);
    }

    function isIOS() {
        return (navigator.userAgent.match(/(iPod|iPhone|iPad)/));

    }

    function useVideoCheck() {

        return ((!viewport.is('xs')) && (!isIOS()));
    }

    function resizeImagesToCover(elements, width, height, postFunction) {
/*
        function reRunResizeImagesToCover(elements, width, height, postFunction) {

            return (function (elementsCopy, widthCopy, heightCopy, postFunctionCopy) {

                    return function () {

                        resizeImagesToCover(elementsCopy, widthCopy, heightCopy, postFunctionCopy);
                    };
                })(elements, width, height, postFunction);
        }
        */

        //Calculate ratio
        var screenRatio = height/width;

        //Set sizes for each image
        elements.each(function() {

            var $this = $(this);

            var elementRatio;

            var isImg = $this.is('img');

            if (isImg) {

                //Calculate aspect ratio
                elementRatio = this.naturalHeight/this.naturalWidth;
            }
            else if (useVideoCheck()) {

                elementRatio = this.videoHeight/this.videoWidth;

            }
            else {
                return;
            }

            //Retry if failed
            if (isNaN(elementRatio)) {

                var existingValue = $this.data('ribcraftemeRetryImagesSize');
                //$this.attr('data-ribcraftemeRetryImagesSize');
                var newValue = existingValue ? existingValue : 1;

                console.log("elementRatio NaN retryCount:" + newValue);

                if (newValue < 20) {
                    newValue++;
                }

                $this.data('ribcraftemeRetryImagesSize', newValue);

                setTimeout(
                    () => {
                        resizeImagesToCover(
                            $this,
                            width,
                            height,
                            postFunction);
                    },
                    newValue * 50);

                return;
            }

            //Screen is narrower
            if (screenRatio > elementRatio) {

                $this.height(height);

                //Calculate respective image width
                var newImageWidth = height/elementRatio;
                $this.width(newImageWidth);

                if (!isImg) {
                    $this.closest(".video-container").height(newImageWidth).width(width);
                }

                //Set margins to centre image
                $this.css('margin-left', - (newImageWidth -
                                            width) / 2);
                $this.css('margin-top', 0);


            }
            //Screen is wider
            else {

                $this.width(width);

                //Calculate respective image height
                var newImageHeight = width*elementRatio;
                $this.height(newImageHeight);

                if (!isImg) {
                    $this.closest(".video-container").height(newImageHeight).width(width);
                }

                //Set margins to centre image
                $this.css('margin-top', - (newImageHeight -
                                            height) / 2);
                $this.css('margin-left', 0);


            }
            if (postFunction) {
                postFunction($this);
            }

        });
    }

    //#########################################################################
    //#########################################################################

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
    // All pages
    'common': {
      init: function() {

        //Ensure
        $('.page-container').bind('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
            function(event) {

                if ($('.page-container').position().left === 0) {

                    $('body').removeClass('transitioning');
                }

        });

        function setHeadlinesToWindowSize() {

            var useVideo = true;
            var displayHeight;

            //Moved hear to enable full screen on mobile as requested
            //Calculate adjustment height
            var adjustmentHeight = $('.logo-and-nav-container').height();
            if (adjustmentHeight < 1) {
                adjustmentHeight = $('.navbar-brand-link').height() +
                                    $('.header-divide-line').height();
            }
            if (adjustmentHeight < 1) {
                console.log('Nav height not correct yet, retying...');
                setTimeout(setHeadlinesToWindowSize, 200);
                return;
            }
            displayHeight = $(window).height() - adjustmentHeight;

            //If not using video disable it
            if (!useVideoCheck()) {

                useVideo = false;

                //$('.headlines').height('auto');
                var imgElements = $('.headlines .headline-image-container img');
                var videoElements = $('.headlines .headline-image-container video');

                videoElements.removeClass('visible');

                videoElements.each(function () {
                    this.pause();
                });

                $('.headlines .headline-cover').fadeOut(500);
                $('.headlines .video-container').hide();

            }
            else {

                //Show video containers
                $('.video-container').show();

            }

            var displayWidth = $(window).width();

            //Ensure height is above min
            if (displayHeight < 400) {
                displayHeight = 400;
            }

            //Set height of headline
            $('.headlines').height(displayHeight);
            resizeImagesToCover(
                $('.headlines .headline-image-container img, .headlines .headline-image-container video'),
                displayWidth,
                displayHeight,
                function (element) {

                    let $element = $(element);

                    let $video = $element.closest('.headline').find('video');

                    // When video present, let video remove headline-cover
                    if (!$video.length) {
                        //Remove cover
                        setTimeout(fadeOutElement(
                            $element.closest('.headline').find('.headline-cover')), 500);
                    }
                    else {

                        let player = videojs($video.attr("id"))

                        // When video playing, remove cover
                        player.ready(() => {
                            player.on("playing", () => {
                                $element.closest('.headline').find('.headline-cover').fadeOut(250);
                            });
                        });
                    }

                });

        }
        if ($('.headlines').length > 0) {
            $(window).on("resizeWidth", setHeadlinesToWindowSize);
            $(window).on("orientationchange", setHeadlinesToWindowSize);
            setHeadlinesToWindowSize();
        }

        //
        function setBlocksToWindowWidth() {

            resizeImagesToCover(
                $('.title-image-text-url img'),
                $(window).width(),
                $('.title-image-text-url .img-container').height());


        }
        if ($('.title-image-text-url img').length > 0) {
            $(window).on("resizeWidth", setBlocksToWindowWidth);
            $(window).on("orientationchange", setBlocksToWindowWidth);
            setBlocksToWindowWidth();
        }

        //Set videos to jump infront of images when they play
        $('.swiper-slide video').bind('play', function () {

            $(this).addClass('visible');
        });

        //Start videos when active on headline, reset other
        function playSlideVideos(swiper) {

            if (!useVideoCheck()) {
                return;
            }

            $('.swiper-slide-active video').each(function () {

                let $this = $(this);

                let player = videojs($this.attr("id"))

                player.ready(() => {
                    this.muted = true;

                    player.play();
                });
            });

            $('.swiper-slide:not(.swiper-slide-active) video').each(function () {

                let $this = $(this);
                let player = videojs($this.attr("id"))

                player.pause()
                this.currentTime = 0;

            });

        }

        var sharedSwiper = {

            direction: 'horizontal',
            grabCursor: true,

            pagination: '.swiper-pagination',

            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',

            simulateTouch: true,

            loop: true,
            autoplay: 9000,

        };


        //Galley homepage
        var swiperHome = new Swiper ('.swiper-container-homepage', {
            ...sharedSwiper,
            loop: false,

            //Video start
            runCallbacksOnInit: false,
            onSlideChangeStart: playSlideVideos,
            onInit: playSlideVideos,

          });

        //General gallery
        var swiperDefault = new Swiper ('.swiper-container-default', {
            ...sharedSwiper,
          });

        //Grid gallery full screen
        $('.swiper-container-fullscreen').each(function () {

            let $gallery = $(this);
            let $galleryContainer = $gallery.closest('.full-screeen-gallery');

            let swiperFull = new Swiper(this, {
                ...sharedSwiper,
                autoplay: false
              });

            //Grid gallery full screen
            $galleryContainer.find('.close-full-screen').click(() => {
                $galleryContainer.removeClass('full-screen');
            });


            let $gridGalleryContainer = $galleryContainer.closest('.grid-gallery-container');

            $gridGalleryContainer.find('.main-image, .overlay-image').click(function () {

                let $this = $(this);

                let index = $this.closest('li').index();

                if (Array.isArray(swiperFull)) {
                    swiperFull.forEach(element => element.slideTo(index + 1, 0));
                }
                else {
                    swiperFull.slideTo(index + 1, 0);
                }

                $galleryContainer.addClass('full-screen');
            });

        });


        $('.swiper-container-default .swiper-wrapper').on('image-added', function () {

            var $this = $(this);

            //Get slides set
            var $slides = $this.children('.swiper-slide').not('.swiper-slide-duplicate');

            swiperDefault.removeAllSlides();

            for (var i=0; i<$slides.length; i++) {
                swiperDefault.appendSlide($slides.eq(i));
            }

            swiperDefault.update(true);

            $this.find('img').css('opacity', 1);

            swiperDefault.slideTo(1);

            setTimeout(function () {

                swiperDefault.slideTo(1);

            }, 100);

        });

        //Vehicle page
        function setVehicleSize() {

            var contentHeight = $('.vehicle-detail .vehicle-content').outerHeight();
            var contentWidth = $('.vehicle-detail .vehicle-content').outerWidth();

            $('.vehicle-detail .vehicle-image').css('height', contentHeight);

            resizeImagesToCover(
                $('.vehicle-detail .vehicle-image img'),
                contentWidth,
                contentHeight);


        }
        if ($('.vehicle-detail .vehicle-image img').length > 0) {
            $(window).on("resizeWidth", setVehicleSize);
            $(window).on("orientationchange", setVehicleSize);
            setVehicleSize();
        }

        $('.back-to-top').click(function (event) {

            event.preventDefault();

            $("html, body").animate({ scrollTop: 0});

        });


        //Extended specification
        $('.extended-specification .open-arrow').click(function (event) {

            event.preventDefault();

            //$(this).toggleClass('flip').parent().find('.specification-list').toggleClass('in');
            var specArrow = $(this);

            $(this).toggleClass('flip').parent().find('.specification-list').slideDown( "slow", function() {

            });


        });


        //Spefication sliders
        var slidesPerView = 6;
        if (viewport.is('xs')) {
            slidesPerView = 2;
        }
        else if (viewport.is('sm')) {
            slidesPerView = 4;
        }
//FIXME do all aria-expanded

        //Menu toggle
        $('.navbar-toggle').click(toggleNav);

        $('.content-container, .header-fill').click(function() {

            let $this = $(this);

            // Avoid duplicate nav button call
  //          if ($this.hasClass('navbar-toggle')) {
  //              return;
  //          }

            // Only close menu when non-nav pressed
            if (!$('body').hasClass('in')) {
                return;
            }

  //          // Ignore elements inside nav
  //          if ($this.parents('nav').length) {
  //              return;
  //          }

            toggleNav();
        });


        function toggleNav() {


            // When in small nav mode scroll to top
            $("html").animate({ scrollTop: 0 }, 200, function () {

                console.log("after ani");

                $('body').toggleClass('in');

                if ($('body').hasClass('in')) {

                    $('body').addClass('transitioning');
                    $('body').attr("aria-expanded", "true");

                }
                else {

                    $('body').attr("aria-expanded", "false");

                }

            });

        }


      },
      finalize: function() {

        //Resize second elements so second tier elements are desired width
        function sizeNestedListElements(listItemSelector, width) {

            $(listItemSelector).each(function () {

                var $this = $(this);
                var childElements = $this.find('li:not(.clearfix)');

                //Resize based on screen size
                if (viewport.is('xs')) {
                    $this.css('width', '');
                    childElements.css('width', '');
                }
                else {

                    //Set sizes
                    var topWidth = (width * childElements.length) + '%';
                    var bottomWidth = (100/childElements.length) + '%';

                    $this.css('width', topWidth);
                    childElements.css('width', bottomWidth);

                }

            });
        }

        function vehicleClassShowHideVehicles() {

            var $this = $(this);

            //
            if ($this.data('ribcraftmeOpen')) {

                //$this.find('.vehicles').css('top', '10px');
                $this.find('.vehicles').css('bottom', '0');
                $this.data('ribcraftmeOpen', false);
            }
            else {

                var vehicles = $this.find('.vehicles');
                vehicles.css('bottom', - vehicles.outerHeight());
                $this.data('ribcraftmeOpen', true);
            }

            //FIXME aria

        }

        function resizeVehicles() {

            //Add/remove click handlers
            if (viewport.is('xs')) {

                if ($('.vehicles-only').length > 0) {

                    $('.vehicle-class').click(vehicleClassShowHideVehicles);
                }

                //Move vehicles into original container
                $('.vehicles-moved-container .vehicle').each(function () {
                    var $this = $(this);

                    //Move
                    $this.appendTo($this.data('ribcraftmeParent'));
                });
            }
            else {

                if ($('.vehicles-only').length > 0) {

                    $('.vehicle-class').unbind("click", vehicleClassShowHideVehicles);

                    //Move vehicles into outer container
                    $('.vehicles-only .vehicle-class .vehicle').each(function () {
                        var $this = $(this);

                        //Record original parent
                        $this.data('ribcraftmeParent', $this.parent());

                        //Move
                        $this.appendTo($('.vehicles-moved-container'));
                    });
                }

            }

        }

        if ($('.vehicle-class').length > 0) {

            $('.vehicles').data('ribcraftmeOpen', false);

            $('.vehicle-class').click(vehicleClassShowHideVehicles);

            $(window).on("resizeWidth", resizeVehicles);
            $(window).on("orientationchange", resizeVehicles);
            $(window).on("load", resizeVehicles);
            resizeVehicles();

        }


//##############################################################################
//##############################################################################

        //Set list items to same height
        function setListToSameHeight() {

            //Run only one instance at a time
            var running = $(window).data('ribcraftSetListToSameHeightRunning');
            if (typeof running === 'undefined') {
                running = false;
            }

            if (running) {

                //Don't run again, it will run itselfs again
                $(window).data('ribcraftSetListToSameHeightRunAgain', true);
                return;

            }



            //
            $('.js-same-height').each(function () {

                var $this = $(this);
                var maxHeight = 0;
                var $children = $this.children('li');

                //Set natural heights
                $children.each(function () {

                    $(this).css('min-height', 'auto');

                });

                //Get natural heights
                $children.each(function () {

                    if ($(this).outerHeight() > maxHeight) {
                        maxHeight = $(this).outerHeight();
                    }

                });

                //Set max height
                $children.each(function () {

                    $(this).css('min-height', maxHeight);

                });


            });

//##############################################################################


            function itemsInRows($list) {

                var rows = [];


                //Step through each element in order.
                var $children = $list.children('li').not('.change-mask');

                var containerWidth = $list.width();
                var rowWidthLeft = 0;

                //var currentRowTop = -1;
                var currentRow = -1;
                for (var i=0; i<$children.length; i++) {

                    var $child = $children.eq(i);
                    var $element = $child;

                    //Check if child visible
                    if (!$child.is(":visible")) {
                        continue;
                    }

                    //Check if inner element needs using
                    var innerElement = $child.find('.js-match-row-heights-use-element');
                    if (innerElement.length > 0) {

                        //Use inner element
                        $element = $(innerElement).add($child);
                    }

                    //Check if new row needed
                    var childWidth = $child.outerWidth(true);
                    rowWidthLeft -= childWidth;

                    if (rowWidthLeft < 0) {

                        rowWidthLeft = containerWidth - childWidth;
                        currentRow++;
                        rows.push([$element]);
                    }
                    else {
                        rows[currentRow].push($element);
                    }

                }

                return rows;

            }


            $('.js-match-row-heights').each(function () {

                var $this = $(this);

                //Add mask over element to hide
                if ($this.find('.change-mask').length > 0) {

                    var $mask = $this.find('.change-mask');

                    //Update current mask count
                    var maskCount = $mask.data('ribcraftMaskCount');
                    if (typeof maskCount === 'undefined') {
                        maskCount = 0;
                    }
                    $mask.data('ribcraftMaskCount', ++maskCount);

                    $mask.show();

                    //Set initial width/height to stop parent size change effecting
                    $mask.css('width', $mask.outerWidth());
                    $mask.css('height', $mask.outerHeight());

                    //After X seconds decrement mask increment, if 0 fadein
                    setTimeout(function () {

                        var maskCount = $mask.data('ribcraftMaskCount');
                        if (maskCount < 2) {
                            $mask.data('ribcraftMaskCount', 0);

                            $mask.fadeOut(300, function () {
                                $mask.css('width', '100%');
                                $mask.css('height', '100%');
                            });

                        }
                        else {
                             $mask.data('ribcraftMaskCount', --maskCount);
                        }


                    }, 600);
                }


                //Calculate rows. Set each element to be same height
                $this.children('li').not('.change-mask').each(function () {

                    var $this = $(this);

                    $this.css('clear', 'none');
                  //  $this.css('min-height', '250px');
                  //  $this.css('max-height', '250px');

                });

                setTimeout(function () {

                    var rows = itemsInRows($this);

                    console.log('rows');
                    console.log(rows);

                    //Set natural heights
                    $this.children('li').not('.change-mask').each(function () {

                        var $this = $(this);

                        $this.css('clear', 'none');
                        $this.css('max-height', 'none');


                        $this.css('min-height', 'auto');
                        $this.find('.js-match-row-heights-set-element').css('min-height',
                                                            'auto');

                        if ($this.hasClass('js-match-row-heights-set-height')) {
                            $this.css('height', 'auto');
                        }

                    });

                    //Run the following after a moment to allow re-rendering
                    setTimeout(function () {

                        //Calculate heights for each row
                        for (var i=0; i<rows.length; i++) {

                            var maxHeight = 0;
                            for (var j=0; j<rows[i].length; j++) {
                                var elementHeight = rows[i][j].outerHeight();

                                if (maxHeight < elementHeight) {
                                    maxHeight = elementHeight;
                                }
                            }

                            rows[i][0].css('clear', 'both');

                            for (j=0; j<rows[i].length; j++) {

                                var child = rows[i][j];

                                child.css('min-height', maxHeight);
                                child.find('.js-match-row-heights-set-element').css('min-height', maxHeight);

                                if (child.hasClass('js-match-row-heights-set-height')) {
                                    child.css('height', maxHeight);
                                }

                            }
                        }

                    }, 100);

                    //Update count and run again if needed
                    var runAgain = $(window).data('ribcraftSetListToSameHeightRunAgain');

                    if (typeof runAgain === 'undefined') {
                        runAgain = false;
                    }

                    $(window).data('ribcraftSetListToSameHeightRunning', false);

                    if (runAgain) {
                        setTimeout(function () {
                            setListToSameHeight();
                        }, 100);
                    }

                }, 100);

            });
        }


        //
        $('.next-section-button').data('visibilityCount', 0);
        $(window).on("elementReorder", function () {

            var button = $('.next-section-button');

            button.data('visibilityCount', button.data('visibilityCount') + 1);

            button.css('visibility', 'hidden');
            setTimeout(function () {

                var visibilityCount = button.data('visibilityCount');

                if (--visibilityCount < 1) {

                    visibilityCount = 0;
                    button.css('visibility', 'visible');
                }
                button.data('visibilityCount', visibilityCount);},

            500);
        });


        if ($('.js-same-height').length > 0 ||
            $('.js-match-row-heights').length > 0) {

            $(window).on("resizeWidth", setListToSameHeight);
            $(window).on("orientationchange", setListToSameHeight);
            $(window).on("load", function () {

                setTimeout(function () {

                    setListToSameHeight();
                }, 500);
            });
            $(window).on("elementReorder", setListToSameHeight);
        }

        $('.next-section-button').click(function () {

            if ($('.options-sections').length < 1) {
                return;
            }

            var optionsSectionsTop = $('.options-sections').offset().top;

            //If top of options section out of view scroll to it
            if (optionsSectionsTop < $(window).scrollTop()) {
                $(window).scrollTop(optionsSectionsTop - 20);

            }

        });

//FIXME
//Make sure appropriate js is in plugin and in theme (style only in here)
//Combine all JS files into one

//##############################################################################


        $('.sub-menu > li').hide();

        $('nav a').click(function () {

          var $this = $(this);

          if ($this.attr("href") !== "#") {
            return;
          }

          var $linkParent = $this.closest('li');

          $linkParent.toggleClass('clicked');

          //Fade in each section
          setTimeout(function () {

              $('.sub-menu > li').stop(true, true).hide();

              var count = 0;
              $('.clicked .sub-menu > li').each(function () {

                var $li = $(this);
                setTimeout(function ($li) {

                  $li.fadeIn(500);

                }, count, $li);

                count += 250;

              });


          }, 100);

          //unclick all other nav pages
          if ($linkParent.hasClass('clicked')) {

              var $nearByLis = $linkParent.siblings('li');
              $nearByLis.removeClass('clicked');

          }

        });


//##############################################################################
//##############################################################################

//Configurator open navigation
function configuratorOpenNav(event) {

    if (viewport.is('xs')) {

        var $this = $(this);
        var $parentNav = $this.closest('.drop-down-nav');

        //If link was clicked and nav open, do nothing.
        if ($this.is('a') &&
            ($parentNav.hasClass('in')) &&
            !$parentNav.hasClass('close-on-click')) {

            return;
        }

        if (!$parentNav.hasClass('close-on-click')) {

            event.preventDefault();
        }

        if ($parentNav.hasClass('in')) {

            //Hide content
            $parentNav.addClass('not-visible');

            //
            $parentNav.removeClass('animate-open');

        }
        else {

            $parentNav.addClass('animate-open');
            $parentNav.addClass('in');

        }
    }
}

$('.drop-down-nav').bind('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',
    function () {

        var $this = $(this);
        if ($this.hasClass('in')) {

            if (!$this.hasClass('animate-open')) {

                $this.removeClass('in');
                $this.removeClass('not-visible');

            }
        }

});

$('.drop-down-nav .nav-arrow button').click(configuratorOpenNav);
$('.drop-down-nav a').click(configuratorOpenNav);

//##############################################################################
//##############################################################################
//Swap design elements over

function swapSelectionNav() {

    var mobileDisplayOrder =
            $('.options-sections-nav + .selection-display').length > 0;

    if (viewport.is('xs')) {

        //Swap if nessecary
        if (!mobileDisplayOrder) {

            $('.options-sections-nav').prependTo('.option-group-section-container');

        }

    }
    else {

        //Swap if nessecary
        if (mobileDisplayOrder) {

            $('.options-sections-nav').prependTo('.options-section-container');

        }
    }

}


if ($('.selection-display').length > 0) {
    $(window).on("resizeWidth", swapSelectionNav);
    $(window).on("orientationchange", swapSelectionNav);
    $(window).on("load", swapSelectionNav);
    $(window).on("elementReorder", swapSelectionNav);
    swapSelectionNav();
}

//#############################################################################
//Options nav
//FIXME move
        var changeOptionsNavMenu = function(navItem) {

            var index = navItem.parent().index();

            //Check if already in correct item
            if (index === $('.options-sections-nav > ul > li.selected').index()) {

                return;

            }

            $('.options-sections-nav > ul > li.link').removeClass('selected');
            $('.options-sections-nav > ul').children().eq(index).addClass('selected');

            $('.options-sections > ul > li').hide();
            $('.options-sections > ul').children().not('.change-mask').eq(index).fadeIn(500);
            setListToSameHeight();



        };

        $('.options-sections-nav a').click(function (event) {

            changeOptionsNavMenu($(this));

            $(this).closest('.options-section-container').trigger('option-section-change');

        });

//#############################################################################
//hashchange
//FIXME move


        function setOptionsNavigation() {

            //Set initial link based on url
            var group = window.location.hash.substr(1);

            if (group) {

                changeOptionsNavMenu(
                    $('.options-sections-nav > ul > li.' + group + ' a'));
            }
            else {
                changeOptionsNavMenu(
                    $('.options-sections-nav > ul > li.link a').first());

            }

        }

        $(window).bind('hashchange', setOptionsNavigation);
        setOptionsNavigation();

//##############################################################################

//##############################################################################
//##############################################################################

//Set min height of configurator nav to allow animation

//Returnable function

function setMaxHeightFunction($element) {

    return (function ($element_) {

        return function () {

            var transitionCount = $element_.data('noTransitionCount');
            if (--transitionCount > 0) {
                $element_.data('noTransitionCount', transitionCount);
                return;
            }
            $element_.data('noTransitionCount', 0);

            $element_.css('max-height', $element_.outerHeight());

            $element_.delay(100).removeClass('no-transition');

        };

    })($element);

}

function setConfiguratorNavMinHeight() {

    var $configuratorNavs = $('.drop-down-nav');

    $configuratorNavs.each(function () {

        var $this = $(this);

        $this.addClass('no-transition');
        var transitionCount = $this.data('noTransitionCount');
        if (typeof transitionCount === 'undefined') {
            transitionCount = 0;
        }
        transitionCount++;
        $this.data('noTransitionCount', transitionCount);

        $this.css('max-height', 'auto');

        setTimeout(setMaxHeightFunction($this), 200);
    });

}
if ($('.drop-down-nav').length > 0) {
    $(window).on("resizeWidth", setConfiguratorNavMinHeight);
    $(window).on("orientationchange", setConfiguratorNavMinHeight);
    $(window).on("elementReorder", setConfiguratorNavMinHeight);
    $(window).load(setConfiguratorNavMinHeight);
}


//##############################################################################
//##############################################################################


$('.selection-summary').each(function () {

    var $this = $(this);



    if ($this.closest('.summary-section').length < 1) {
        $this.scrollToFixed({

spacerClass: 'fixed',
marginTop: function () {

var thisTop = ($this.outerHeight(true) - $this.outerHeight());

if ($this.hasClass('scroll-to-fixed-fixed')) {

    return thisTop;

}

return thisTop/2;
},


dontSetWidth: true,
limit: function () {

var limit = $('footer').offset().top - $('.selection-summary').outerHeight(true);
return limit;
}
});

    }

});



//#######

$('.open-summary').click(function () {

    $(this).parent().toggleClass('in');

});


//##########
//##############################################################################
//##############################################################################
//##############################################################################
//





//##############################################################################
////##############################################################################
////##############################################################################
////##############################################################################

        //For objects that want to react to being in view
        $('.change-when-in-view').each(function () {

            $(this).waypoint(function(direction) {

                if (direction === 'down') {
                    $(this.element).addClass('in-view');
                }
                else {
                    $(this.element).removeClass('in-view');
                }

            },
            {
                offset: '60%'
            }
            );

        });



        //For objects that want to react to being in view
        $('.change-when-in-view').each(function () {

            if ($(this).hasClass('keep-changed')) {
                return;
            }


            $(this).waypoint(function(direction) {

                if (direction === 'up') {
                    $(this.element).addClass('in-view');
                }
                else {
                    $(this.element).removeClass('in-view');
                }

            },
            {
                offset: '10%'
            }
            );

        });



      }
    },
    // Home page
    'home': {
      init: function() {

        $('.jump-arrow a').click(function (event) {

            event.preventDefault();

            $("html, body").animate({ scrollTop: ($('.headlines').height() + $('.headlines').offset().top) });

        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },


    'news': {
      init: function() {

        //Move everything into link
        $('.news-overview li a').each(function () {


            var $this = $(this);

            var parentLi = $this.parents('.news-overview li');

            //Move out to li
            $this.appendTo(parentLi);

            //Move everything else inside
            $this.prepend(parentLi.find('div'));

        });
/*
 // FIXME now using cover
        //News items
        function setNewsItemSize() {

            resizeImagesToCover(
                $('.news-overview img'),
                $('.news-overview > li:first-child').width(),
                $('.news-overview .img-container').height());


        }
        if ($('.news-overview img').length > 0) {
            $(window).on("resizeWidth", setNewsItemSize);
            $(window).on("orientationchange", setNewsItemSize);
            setNewsItemSize();
        }

        */

      }
    },


    'events': {
        init: function() {

            //Events page backgrounds
            function setEventsItemSize() {

                //Check for small screen
                if (viewport.is('xs')) {

                    $('.event-image img').css('height', 'auto').css('width', 'auto').css('margin', 0);
                    return;
                }


                var windowHeight = $(window).width();

                $('.events li').each(function () {

                    var $this = $(this);

                    var contentHeight = $this.outerHeight();

                    resizeImagesToCover(
                        $this.find('.event-image img'),
                        windowHeight,
                        contentHeight);

                });

            }
            if ($('.events li').length > 0) {
                $(window).on("resizeWidth", setEventsItemSize);
                $(window).on("orientationchange", setEventsItemSize);
                setEventsItemSize();
            }
        }
    },
    'used_boats': {
      init: function() {
        $('.used-vehicles-order select').on('change', function(){
            $(this).closest('form').submit();
        });
      }
    },
    'contact': {
      init: function() {
      }
    }

    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);



})(jQuery, ResponsiveBootstrapToolkit); // Fully reference jQuery after this point.
